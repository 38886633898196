<template>
    <div class="container">
        <page-header
            :title="`알림 받을 분야 선택`"
            :subtitle="`선택한 분야에 새로운 의뢰가 등록되면 바로 알려드려요.\n* 기타 : 8개 분야 외의 의뢰 알림`"
        />

        <div class="ui-border-line ui-h-0 ui-mb-2" />

        <div>
            <tag-box>
                <template v-for="(row, key) in genres">
                    <li
                        :key="key"
                        :class="{
                            on: selectedGenres.includes(row),
                        }"
                        @click="
                            () => {
                                if (ignoredGenres.includes(row)) {
                                    return
                                }

                                const index = selectedGenres.findIndex((val) => val === row)

                                if (index !== -1) {
                                    selectedGenres.splice(index, 1)
                                    return
                                }

                                selectedGenres.push(row)
                            }
                        "
                        v-if="$lib.t_genre(row) != '행사패키지' && $lib.t_genre(row) != '강연자' && $lib.t_genre(row) != '연예인'"
                    >
                        {{ $lib.t_genre(row) }}
                    </li>
                </template>
            </tag-box>
        </div>

        <div class="ui-border-line ui-h-0 ui-mb-4" />

        <footer-box
            submitText="설정하기"
            :submitCb="
                () => {
                    pushSetting()
                }
            "
            :submitDisabled="!validate"
        />
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import TagBox from '@/components/common/TagBox.vue'

export default {
    components: {
        PageHeader,
        FooterBox,
        TagBox,
    },
    data() {
        const userData = this.$store.state.user.userData
        const mainProfile = this.$store.state.user.mainProfile
        const genres = (this.$store.state.user?.genreText || []).map(v => v.genre)

        const selectedGenres: string[] = userData?.pushGenre?.split?.(',') ?? []
        const ignoredGenres = []

        const artistGenres = String(mainProfile.genre || '')
            .split(',')
            .map(row => row.trim())

        if (artistGenres.length) {
            ignoredGenres.push(...artistGenres)
        }

        artistGenres.forEach((artistGenre) => {
            if (selectedGenres.includes(artistGenre)) {
                return
            }
            selectedGenres.push(artistGenre)
        })

        return {
            userData,
            mainProfile,
            genres,
            selectedGenres,
            ignoredGenres,
        }
    },
    computed: {
        validate() {
            return this.selectedGenres.length > 0
        },
    },
    methods: {
        pushSetting() {
            if (!this.validate) return

            const genre = this.selectedGenres.join(',')
            const pushChatOn = this.userData.pushChatOn
            const pushLocation = this.userData?.pushLocation

            const userData = { pushOn: 'on', pushChatOn, pushGenre: genre, pushLocation }

            const req = {
                method: 'put',
                url: `/user/pushSetting/on`,
                data: {
                    genre,
                    chat: pushChatOn,
                    location: pushLocation,
                },
            }

            this.$store.commit('setUserData', userData)

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$router.replace('/setting', () => {
                            this.$toast('알림이 설정(ON)되었습니다.')
                        })
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
    },
}
</script>

<style lang="scss" scoped></style>
