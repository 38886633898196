var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("page-header", {
        attrs: {
          title: "알림 받을 분야 선택",
          subtitle:
            "선택한 분야에 새로운 의뢰가 등록되면 바로 알려드려요.\n* 기타 : 8개 분야 외의 의뢰 알림"
        }
      }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-2" }),
      _c(
        "div",
        [
          _c(
            "tag-box",
            [
              _vm._l(_vm.genres, function(row, key) {
                return [
                  _vm.$lib.t_genre(row) != "행사패키지" &&
                  _vm.$lib.t_genre(row) != "강연자" &&
                  _vm.$lib.t_genre(row) != "연예인"
                    ? _c(
                        "li",
                        {
                          key: key,
                          class: {
                            on: _vm.selectedGenres.includes(row)
                          },
                          on: {
                            click: function() {
                              if (_vm.ignoredGenres.includes(row)) {
                                return
                              }

                              var index = _vm.selectedGenres.findIndex(function(
                                val
                              ) {
                                return val === row
                              })

                              if (index !== -1) {
                                _vm.selectedGenres.splice(index, 1)
                                return
                              }

                              _vm.selectedGenres.push(row)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$lib.t_genre(row)) + " ")]
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-4" }),
      _c("footer-box", {
        attrs: {
          submitText: "설정하기",
          submitCb: function() {
            _vm.pushSetting()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }